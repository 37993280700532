@font-face {
    font-family: "Avenir Next";
    font-style: normal;
    font-weight: 400;
    src: url("../src/Assets/Fonts/AvenirNextLTPro-Regular.otf") format("opentype");
}
@font-face {
    font-family: "Avenir Next";
    font-style: normal;
    font-weight: 500;
    src: url("../src/Assets/Fonts/AvenirNextLTPro-Medium.otf") format("opentype");
}
@font-face {
    font-family: "Avenir Next";
    font-style: normal;
    font-weight: 600;
    src: url("../src/Assets/Fonts/AvenirNextLTPro-Demi.otf") format("opentype");
}
.MuiDialogActions-root>:not(:first-of-type){
    margin-left: 0 !important;
}
.PrivatePickersYear-root,
.MuiMonthPicker-root{
    .PrivatePickersYear-yearButton,
    .PrivatePickersMonth-root{
        font-size: unset;
    }
}
.MuiDialogActions-root{
    padding: 0 !important;
}
::-webkit-scrollbar { width: 5px; } 
::-webkit-scrollbar-thumb { background: #cccc; }